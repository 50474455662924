import { useContext, useState } from "react";
import PocketBase from 'pocketbase'
import { ViewContext } from "../App";
const pb = new PocketBase('https://snowy-sky-226.fly.dev');

function SignUp() {

  const [loginHover, setLoginHover] = useState(false);
  const [password, setPassword] = useState("");
  const [identifier, setIdentifier] = useState("");
  const [username, setUsername] = useState("");
  const [error, setError] = useState<{ message?: string, data: {key: string, message: "string"} }>();
  const [loading, setLoading] = useState(false);
  const [verifyEmail, setVerifyEmail] = useState(false);
  const [info, setInfo] = useState(false);
  const {view, setView} = useContext(ViewContext)

  async function signUp() {
    setError(undefined)
    setLoading(true);
    try {
      await pb.collection("users").create({
        email: identifier,
        username,
        password,
        passwordConfirm: password,
      });

      try {
        await pb.collection("users").requestVerification(identifier);
        setVerifyEmail(true);
        // message = "Check your email for the login link";
      } catch (error) {
        // @ts-ignore
        setError(error)
        setLoading(false);
      }
    } catch (error) {
      // @ts-ignore
      setError(error.data)
      setLoading(false);
    }
  }

  return (
    <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: 'center', fontFamily: '"Source Sans Pro", sans-serif'}}>
      <div className="card">
          <div style={{height: '40px'}}>
            <h4 onClick={() => { setView("Login") }} style={{color: 'rgb(10, 110, 255)', cursor: 'pointer', padding: "0", margin:0, width: '60px'}}>{'‹'} Back</h4>
          </div>
        <div style={{display: "flex", flexDirection: "column"}} >
          {verifyEmail && (
            <>
              <div style={{textAlign: 'center'}}>
                <img style={{}} src="https://media.discordapp.net/attachments/1034834531502534756/1108206899435946064/star_guy.png" width={256}/>
                <h1 style={{margin: '.25rem', marginBottom: '0'}}>Email Verification Required!</h1>
                <h3 style={{marginTop: '.25rem', color: '#808080', fontWeight: 'normal'}}>Check your email to confirm and verify your account creation.</h3>
                <button onClick={() => {
                  // setSignUp(false)
                }} style={{ margin: "1em 0", cursor: 'pointer', backgroundColor: 'rgb(10, 110, 255)', border: 'rgb(16, 63, 239)', borderRadius: '.5rem', padding: '.75em', width: '100%', color: 'white', fontWeight: 'bold' }} >
                  CONTINUE
                </button>
              </div>
            </>
          )}
          {!verifyEmail && (
              <>
                <h2 style={{margin: '0em 0em .5em 0em'}}>Create an account</h2>
                <input onInput={(e)=> {
                  // @ts-ignore
                  setUsername(e.target.value)
                }} type='text' placeholder='Username' style={{ padding: '.75em', borderRadius: '0.5em', margin: '1em 0em', width: '300px', borderColor: 'black'}} />
                <input onInput={(e)=> {
                  // @ts-ignore
                  setIdentifier(e.target.value)
                }} type='email' placeholder='Email Address' style={{ padding: '.75em', borderRadius: '0.5em', margin: '1em 0em', width: '300px', borderColor: 'black'}} />
                <input onInput={(e)=> {
                  // @ts-ignore
                  setPassword(e.target.value)
                }} type='password' placeholder='Password' style={{ padding: '.75em', borderRadius: '0.5em', margin: '1em 0em', width: '300px', borderColor: 'black'}} />
                <button disabled={loading} onClick={signUp} style={{ margin: "1em 0", cursor: 'pointer', backgroundColor: loading ?  'rgb(128, 128, 128)' : 'rgb(10, 110, 255)', border: 'rgb(16, 63, 239)', borderRadius: '.5rem', padding: '.75em', width: '100%', color: 'white', fontWeight: 'bold' }} >
                  SIGN UP
                </button>
                {info && (
                  <div style={{backgroundColor: "#cfe2ff", color: '#084298', border: '1px solid', borderColor: '#b6d4fe', borderRadius: '10px', padding: "1em", marginBottom: '1em'}}>
                    <p style={{margin: 0}}>
                      <p style={{ margin: 0 }}>{info}</p>
                    </p>
                  </div>
                )}
                {error && (
                      <div style={{backgroundColor: "#FFCDD2", color: '#842029', border: '1px solid', borderColor: '#f5c2c7', borderRadius: '10px', padding: "1em"}}>
                      <p style={{margin: 0}}>
                        <p style={{ margin: 0 }}>{error?.message}</p>
                        {error && error.data && Object.keys(error.data).map((key) => {
                          return (
                            // @ts-ignore
                            <p style={{ margin: 0}}>{key} - {error?.data[key].message}</p>
                          )
                        })}
                      </p>
                    </div>
                )}
              </>
          )}
        </div>
      </div>
    </div>
  )
}

export default SignUp;