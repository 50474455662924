import React, { useEffect, useState } from 'react';
import background from './castle_background.png'
import logo from './paperville.png'
import './App.css';
import { Box } from '@mui/material';
import Starting from './views/Starting';
import Login from './views/Login';
import SignUp from './views/SignUp';
import LoggedIn from './views/LoggedIn';

export type Views = 'Join' | 'Login' | 'SignUp' | 'ForgotPassword' | 'LoggedIn'
export const ViewContext = React.createContext({view: "Join", setView: (e: Views)=>{}});
function App() {

  const [view, setView] = useState<Views>('Join');
  const contextValue = { view, setView };

  function viewToShow() {
    if (view === "Join"){
      return <Starting />
    } else if (view === "LoggedIn") {
      return <LoggedIn />
    }
    else if (view === "Login") {
      return <Login />
    } else if (view === "SignUp") {
      return <SignUp />
    } else {
      return <Starting />
    }
  }

  return (
    <ViewContext.Provider value={contextValue}>
      <Box sx={{background: `url(${background})`, width: '100%', height: '100%', backgroundSize: 'cover', fontFamily: "'Source Sans Pro', sans-serif"} }>
        <Box sx={{height: '80%'}}>
          <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', paddingTop: '100px'}}>
            <Box style={{}}>
              <img className="logo" src={logo} />
            </Box>
            <Box className="seperator"></Box>
            {viewToShow()}
          </Box>
        </Box>
        <Box sx={{borderTop: '2px solid', borderColor: '#43535d', backgroundColor: '#1f3141', height: '15%', width: '100%', p: 1, minHeight: '100px'}}>
          <div style={{display: 'flex', flexDirection: 'column', alignContent: 'center', alignItems: 'center'}}>
            <div className='discord'>
              <a href="https://discord.gg/2veRkFzm68">
                <img width={'100%'} src="https://discord.com/api/guilds/1102371335734313100/widget.png?style=banner3" />
              </a>
            </div>
          </div>
        </Box>
      </Box>
    </ViewContext.Provider>
  );
}

export default App;
