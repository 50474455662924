import { useContext, useEffect, useState } from "react";
import PocketBase from 'pocketbase'
import { ViewContext } from "../App";
const pb = new PocketBase('https://snowy-sky-226.fly.dev');

function Login() {

    const [loginHover, setLoginHover] = useState(false);
    const [password, setPassword] = useState("");
    const [identifier, setIdentifier] = useState(pb.authStore?.model?.email);
    const [loggingIn, setLoggingIn] = useState(false);
    const [error, setError] = useState<string>();
    const {view, setView} = useContext(ViewContext)
    const [emailUnverified, setEmailUnverified] = useState<boolean>();


    useEffect(() => {
      if (pb.authStore.model?.verified === false) {
        setEmailUnverified(true)
        console.log(pb.authStore.model)
      } else {
        setEmailUnverified(false)
      }
    }, [])

    async function sendLogin() {
      if (!loggingIn) {
        setLoggingIn(true)
        const userData = await pb.collection('users').authWithPassword(identifier, password).catch((err) => {
          if (err?.response?.message) {
            setError(err.response.message)
          } else {
            setError("Unexpected error");
          }
          setLoggingIn(false);
          return;
        });

        console.log(userData)

        if (!userData) {
          setLoggingIn(false);
          return;
        }
        setLoggingIn(false);
        if (userData.record?.verified === true) {
        //   setToken(userData.token)
          setView("LoggedIn")
        } else if(userData.record?.verified === false) {
          setEmailUnverified(true);
          setError(undefined)
        }
      }
    }

    const [resendingVerify, setResendingVerify] = useState(false);

    function resendVerify() {
      if (!resendingVerify) {
        setResendingVerify(true);
        pb.collection("users").requestVerification(identifier);
      }
    }

    // if (checkingLocalAuth) {
    //   return <div><progress style={{width: "100%"}}  /></div>
    // }

    // @ts-ignore
    function onChangeUsername(e) {
      setIdentifier(e.target.value) 
    //   setEmailUnverified(false);
      setLoggingIn(false);
      setError(undefined)
    }

    return (


      <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: 'center', fontFamily: '"Source Sans Pro", sans-serif'}}>
      <div className="card">
          {/* {(signUp || passwordReset) && (
            <div style={{height: '40px'}}>
              <h4 onClick={() => { setSignUp(false); setPasswordReset(false)}} style={{color: 'rgb(10, 110, 255)', cursor: 'pointer', padding: "0", margin:0, width: '60px'}}>{'‹'} Back</h4>
            </div>
          )} */}

        <div style={{display: "flex", flexDirection: "column"}} >
          <h2 style={{margin: '0em 0em .5em 0em'}}>Sign In</h2>
          <input defaultValue={pb.authStore?.model?.email} disabled={loggingIn} onInput={onChangeUsername} type='email' placeholder='Email Address' style={{ padding: '.75em', borderRadius: '0.5em', margin: '1em 0em', width: '300px', borderColor: 'black'}} />
          <input disabled={loggingIn} onInput={(e)=> { 
            // @ts-ignore
            setPassword(e.target!.value)
          }} onKeyDown={(e) => {
            if (e.key == "Enter"){
              sendLogin();
            }
          }} type='password' placeholder='Password' style={{ padding: '.75em', borderRadius: '0.5em', margin: '1em 0em', width: '300px', borderColor: 'black'}} />
          {/* <div style={{display: 'flex', alignItems: 'center', gap: '.5em', marginBottom: '.5em'}}>
            <input type='checkbox'/> <span>Remember Me</span>
          </div> */}
          <button disabled={loggingIn} onClick={sendLogin} onMouseLeave={()=> setLoginHover(false)} onMouseEnter={()=> setLoginHover(true)} style={{ margin: "1em 0", cursor: loggingIn ? 'auto' : 'pointer', backgroundColor: loggingIn ? 'rgb(128, 128, 128)' : loginHover ? 'rgb(10, 110, 255)' : 'rgb(80, 110, 255)', border: 'rgb(16, 63, 239)', borderRadius: '.5rem', padding: '.75em', width: '100%', color: loggingIn ? 'black' : 'white', fontWeight: 'bold' }} >
            {loggingIn ? '...' : 'LOGIN'}
          </button>
          <div style={{color: "#808080"}}>
            No account? <span onClick={() => {
              setView("SignUp")
            }} style={{cursor: "pointer", color: 'rgb(10, 110, 255)', marginRight: '1rem'}}>Sign up</span>
            Forgot password? <span onClick={() => {
              // setPasswordReset(true)
            }} style={{cursor: "pointer", color: 'rgb(10, 110, 255)'}}>Reset password</span>
          </div>
          <div style={{color: "#808080", marginTop: '1rem'}}>

          </div>
          {emailUnverified === true && (
              <>
              <div style={{backgroundColor: "#FFCDD2", color: '#842029', border: '1px solid', borderColor: '#f5c2c7', borderRadius: '10px', padding: "1em", marginTop: '1rem'}}>
                <p style={{margin: 0}}>
                  <p style={{ margin: 0 }}>It appears your email address is unverified. Please verify the email address by confirming the email sent to your email address.</p>
                </p>
              </div>
              <p style={{ margin: 0, marginTop: '1em', color: '#808080' }}>Didn't receive an email? <span onClick={resendVerify} style={{cursor: 'pointer', color: !resendingVerify ? 'rgb(10, 110, 255)' : 'green'}}>{resendingVerify ? 'Email Sent!' : 'Resend verification.'}</span></p>
              </>
          )}

          {error && (
              <div style={{backgroundColor: "#FFCDD2", color: '#842029', border: '1px solid', borderColor: '#f5c2c7', borderRadius: '10px', padding: "1em", marginTop: '1rem'}}>
                <p style={{margin: 0}}>
                  <p style={{ margin: 0 }}>{error}</p>
                </p>
              </div>
          )}

        </div>
        </div>
      </div>
    )
  }

export default Login;