import React, { useContext, useEffect, useState } from 'react';
import character1 from '../character/archer_feminine.png';
import character2 from '../character/archer_masculine.png';
import character3 from '../character/bard_feminine.png';
import character4 from '../character/bard_masculine.png';
import character5 from '../character/cleric_feminine.png';
import character6 from '../character/cleric_masculine.png';
import character7 from '../character/warrior_feminine.png';
import character8 from '../character/warrior_masculine.png';
import character9 from '../character/wizard_feminine.png';
import character10 from '../character/wizard_masculine.png';
import '../App.css';
import { Box } from '@mui/material';
import { ViewContext } from '../App';
import PocketBase from 'pocketbase'
const pb = new PocketBase('https://snowy-sky-226.fly.dev');

function Starting() {

    const all_characters = [character1, character2, character3, character4, character5, character6, character7, character8, character9, character10]
    const [currentCharIndex, setCurrentCharacterIndex] = useState(0)
    const {view, setView} = useContext(ViewContext)

    useEffect(() =>{
      setTimeout(() => {
        if (currentCharIndex < 9) {
          setCurrentCharacterIndex(currentCharIndex + 1)
        } else {
          setCurrentCharacterIndex(0)
        }
      }, 1000)
    }, [currentCharIndex])
  
    return (
        <Box>

            <Box style={{}}>
                <img  className='character' src={all_characters[currentCharIndex]} />
            </Box>
            <Box className="seperator"></Box>
            <button onClick={() =>{
                if (pb.authStore.isValid && pb.authStore.model?.id && pb.authStore.model?.verified) {
                  setView("LoggedIn")
                } else {
                  setView("Login")
                }
            }} className='join'>
                Join The World
            </button>
        </Box>
    );
  }

export default Starting;