import { useContext, useState } from "react";
import PocketBase from 'pocketbase'
import { ViewContext } from "../App";
const pb = new PocketBase('https://snowy-sky-226.fly.dev');

function LoggedIn() {
  const {view, setView} = useContext(ViewContext)
  return (
    <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: 'center', fontFamily: '"Source Sans Pro", sans-serif'}}>
      <div className="card">
        <h4 onClick={() => { 
            pb.authStore.clear()
            setView("Login")
          }} style={{color: 'rgb(10, 110, 255)', cursor: 'pointer', padding: "0", margin:0, float: 'right'}}>Sign Out </h4>
        <h2>Hi, {pb.authStore.model?.username}</h2>
        <p>Thank you for registering. We hope you enjoy the people, the adventures, and your time in the lands of Paperville. </p>
        <button onClick={() =>{
                window.open("https://github.com/Xwaffle1/Paperville-Launcher-AutoUpdater/releases", "_blank")
            }} className='join'>
                Download
            </button>
      </div>
    </div>
  )
}


export default LoggedIn;